<template>
  <div id="data-view">
    <!-- <dv-full-screen-container> -->
    <top-header />
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div class="main-content" v-show="!loading">
      <div class="block-left-content">
        <alarm-rate :content-data="dataObj.exceedStandardsByMonth"></alarm-rate>
        <emission-reduce-six
          :content-data="dataObj.redEmissionsByMonth"
        ></emission-reduce-six>
        <alarm-data :content-data="dataObj.alarmData"></alarm-data>
      </div>
      <div class="block-center-content">
        <!-- <map-guide /> -->
        <center :content-data="dataObj" @cityChange="getAreaCode"></center>
      </div>
      <div class="block-right-content">
        <quick-entry :content-data="dataObj"></quick-entry>
        <region-compare :content-data="dataObj"></region-compare>
      </div>
    </div>
    <!-- </dv-full-screen-container> -->
  </div>
</template>

<script>
import topHeader from "./topHeader";
import alarmRate from "./alarmRate";
import emissionReduceSix from "./emissionReduceSix";
import alarmData from "./alarmData";

import quickEntry from "./quickEntry_dis";
import regionCompare from "./regionCompare_dis";

import center from "./center";
import { stg } from "@/util/index";

export default {
  name: "DataView",
  components: {
    topHeader,
    alarmRate,
    emissionReduceSix,
    alarmData,

    quickEntry,
    regionCompare,

    center,
  },
  data() {
    return {
      timer: null, // 时间对象
      loading: true,
      postdata: {
        WithSumData: true, //是否返回汇总数据,
        WithOnlineDevice: true, //返回在线设备,
        WithTopList: true, // 返回top5
      },
      dataObj: {
        exceedStandardsByMonth: [],
        alarmData: [],
        redEmissionsByMonth: [],
      },
    };
  },
  mounted() {
    this.getAreaCode(); // 获取数据
    // this.timer = setInterval(() => {
    //   this.netGetData()
    // }, 60000 * 30)
  },
  beforeDestroy() {
    // this.timer = null
    // clearInterval(this.timer)
  },
  methods: {
    /**
     * @description 获取数据
     */
    netGetData() {
      this.loading = true;
      this.$post("admin/listBoardDataV2", this.postdata)
        .then((res) => {
          const exceedStandardYesterdays = (
            res.countyLevelData.exceedStandardYesterdays || []
          ).map((v) => {
            v.type = "超标报警";
            return v;
          });
          const filterAbnormallyUsedYesterdays = (
            res.countyLevelData.filterAbnormallyUsedYesterdays || []
          ).map((v) => {
            v.type = "不正常使用净化器";
            return v;
          });
          // const exceedStandardYesterdays = [
          //   {
          //     type: "超标报警",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          //   {
          //     type: "超标报警",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          //   {
          //     type: "超标报警",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          // ];
          // const filterAbnormallyUsedYesterdays = [
          //   {
          //     type: "不正常使用净化器",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          //   {
          //     type: "不正常使用净化器",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          //   {
          //     type: "不正常使用净化器",
          //     LocaleName: "四季庭院-总烟道",
          //     LocaleAddress: "浙江杭州市余杭经济开发区临平星河路61号",
          //     AcquitAt: 1611849600,
          //   },
          // ];
          this.dataObj = {
            ...this.dataObj,
            ...res,
            code: this.postdata.AreaCode,
            level: this.postdata.level,
            // 近6个月超标报警次数
            exceedStandardsByMonth:
              res.countyLevelData.exceedStandardsByMonth || [],
            // 近6个月减排
            redEmissionsByMonth: res.countyLevelData.redEmissionsByMonth || [],
            alarmData: [
              ...exceedStandardYesterdays,
              ...filterAbnormallyUsedYesterdays,
            ],
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAreaCode(data) {
      // console.log(data, 'getAreaCode')
      if (data && data.code) {
        this.postdata.AreaCode = data.code + "";
        this.postdata.level = data.level + "";
      } else {
        this.postdata.AreaCode = stg().getItem("quCode");
        this.postdata.level = stg().getItem("level");
      }
      this.netGetData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/common.scss";

#data-view {
  width: 100vw;
  height: 100vh;
  background-color: #011d24;
  color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;

  // #dv-full-screen-container {
  //   background-image: url('./img/bg.png');
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 3px blue;
  //   background-color: #011D24;
  //   display: flex;
  //   flex-direction: column;
  //   box-sizing: border-box;
  //   overflow-y: auto;
  // }

  .main-content {
    flex: 1;
    display: flex;
    padding: 0 15px 10px;
    height: 90vh;
  }

  .block-left-content,
  .block-center-content,
  .block-right-content {
    display: flex;
    // padding: 0 10px 0 0;
  }
  .block-left-content,
  .block-right-content {
    width: 18vw;
    box-sizing: border-box;
    flex-direction: column;
  }

  .block-left-content {
    .online_rate,
    .reach_rate_page {
      // flex: 1;
      height: 25%;
    }
    .alarm_data {
      height: 50%;
    }
  }

  .block-right-content {
    > div {
      // margin-bottom: 16px;
      // background:linear-gradient(-39deg,rgba(12,116,214,0.04),rgba(19,50,121,0.04));
      height: 50%;
      // &:nth-of-type(1) {
      //   margin-bottom: 16px;
      //   // flex: 1;
      // }

      // &:nth-of-type(2) {
      //   // flex: 1.5;
      // }
    }
  }
  .block-center-content {
    width: 64vw;
    // flex: 1;
  }
}

.section_title_span {
  font-size: 11px;
  margin-left: 3px;
}
</style>
